import React from "react"
import HeroStandardNoReference from "./components/HeroStandardNoImage"
import HeroStandardWithReference from "./components/HeroStandardWithReference"
import setTheme from "./utils/setTheme"

const HeroStandard = ({ component, location, seoBreadcrumbs }) => {
  const theme = setTheme(component?.background);
  if (component?.reference) {
    return (
      <HeroStandardWithReference
        component={component}
        headingColor={theme?.headingColor}
        fontColor={theme?.fontColor}
        kickerColor={theme?.kickerColor}
        backgroundColor={theme?.backgroundColor}
        backgroundPattern={theme?.backgroundPattern}
        location={location}
        seoBreadcrumbs={seoBreadcrumbs}
      />
    )
  } else if (!component?.reference) {
    return (
      <HeroStandardNoReference
        component={component}
        headingColor={theme?.headingColor}
        fontColor={theme?.fontColor}
        kickerColor={theme?.kickerColor}
        backgroundColor={theme?.backgroundColor}
        location={location}
        seoBreadcrumbs={seoBreadcrumbs}
      />
    )
  }
}

HeroStandard.displayName = "HeroStandard"
export default HeroStandard
