import React from "react"
import { heroResolveRichText } from "../../../utils/resolve-rich-text/heroResolveRichText"
import { Heading, Text, TextMono, Button } from "@atoms"
import MktoForm from "../../Form/MktoForm"
import OptimizedImage from "../../../utils/optimizedImage"
import { LottieWrapper } from "../../Lottie/LottieWrapper"
import {
  HeroStandardContainer,
  CategoriesWrapper,
  Category,
  BreadcrumbContainer,
} from "../styles/HeroStandard.styled"
import Breadcrumbs from "./Breadcrumbs"

const HeroStandardWithReference = ({
  component,
  headingColor,
  fontColor,
  kickerColor,
  backgroundColor,
  backgroundPattern,
  location,
  seoBreadcrumbs,
}) => {
  const {
    heading,
    headingSize,
    subheading,
    kicker,
    alignImage,
    callsToAction,
    type,
    reference,
    background,
    featuredImage,
    breadcrumbs,
    breadcrumbTitle,
    categories,
    mobileTextAlign,
  } = component

  const isTall = type.includes("tall")
  let lottie = reference?.primaryImage?.file?.contentType?.includes("json")

  return (
    <HeroStandardContainer
      alignImage={alignImage}
      isTall={isTall}
      reference={reference}
      className="flex"
      background={backgroundColor}
      backgroundPattern={backgroundPattern}
      mobileTextAlign={mobileTextAlign}
    >
      {featuredImage && !isTall && (
        <OptimizedImage
          className="background-image"
          image={featuredImage.gatsbyImageData}
          src={featuredImage?.file?.url}
          alt=""
          loading="eager"
        />
      )}
      {breadcrumbs && (
        <BreadcrumbContainer>
          <Breadcrumbs
            background={background}
            location={location}
            seoBreadcrumbs={seoBreadcrumbs}
            breadcrumbTitle={breadcrumbTitle}
          />
        </BreadcrumbContainer>
      )}
      <div className="container">
        <div className="text-content-side">
          <div className="text-content-wrapper">
            {categories && (
              <CategoriesWrapper>
                {categories?.map((category, index) => (
                  <Category
                    key={index}
                    style={{
                      background: category?.backgroundColor,
                      color: category?.fontColor || "#1B1B1B",
                    }}
                  >
                    {category?.title}
                  </Category>
                ))}
              </CategoriesWrapper>
            )}
            {kicker && (
              <TextMono
                style={{
                  color: kickerColor,
                  paddingBottom: "12px",
                }}
              >
                {kicker}
              </TextMono>
            )}
            {heading && (
              <Heading
                style={{
                  paddingBottom: "24px",
                  color: headingColor,
                }}
                headingSize={headingSize}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Text
                style={{
                  color: fontColor,
                }}
              >
                {heroResolveRichText(component, background)}
              </Text>
            )}
            <div className="btn-wrapper">
              {callsToAction &&
                callsToAction[0]?.link &&
                callsToAction.map(btn => (
                  <Button
                    ctaVariant={btn?.variant || "primary"}
                    ctaLink={btn?.link}
                  >
                    {btn?.label}
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div id="padding-for-space-between-sides" style={{ padding: "20px" }} />
        <div className="img-side">
          {reference?.__typename === "ContentfulComponentImage" && !lottie && (
            <OptimizedImage
              loading="eager"
              image={reference?.primaryImage?.gatsbyImageData}
              src={reference?.primaryImage?.file.url}
              alt={reference?.primaryImage?.description || reference?.primaryImage?.title || "Featured Image" }
            />
          )}
          {reference?.__typename === "ContentfulComponentImage" && lottie && (
            <LottieWrapper
              imageAnimation={reference?.primaryImage?.file?.url}
            />
          )}
          {reference.__typename === "ContentfulComponentForm" && (
            <MktoForm
              formId={reference?.marketoFormId}
              background={reference?.background}
              component={reference}
            />
          )}
        </div>
      </div>
    </HeroStandardContainer>
  )
}

export default HeroStandardWithReference
