import styled, { css } from "styled-components"
import LightPattern from "../assets/light-pattern.svg"
import DarkPattern from "../assets/dark-pattern.svg"
import BluePattern from "../assets/blue-pattern.svg"
import PurplePattern from "../assets/purple-pattern.svg"

const patternConfig = {
  "light-pattern": LightPattern,
  "dark-pattern": DarkPattern,
  "blue-pattern": BluePattern,
  "purple-pattern": PurplePattern,
}

export const HeroStandardContainer = styled.div`
  font-family: Roboto, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  align-items: center;
  overflow: hidden;
  ${({ background }) => `
    background-color: ${background};
  `}
  ${props =>
    props.backgroundPattern &&
    css`
      background-image: url(${patternConfig[props.backgroundPattern]});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `}
  .background-image {
    position: absolute;
    top: 0;
    min-width: 100vw;
    min-height: 100%;
    z-index: 0;
  }
  .container {
    z-index: 1;
    display: flex;
    min-height: 500px;
    margin: 0 auto;
    max-width: 1170px;
    width: 100%;
    align-items: center;
    padding: 0 15px;
    @media (max-width: 992px) {
      max-width: 770px;
      flex-direction: column;
      margin-top: 64px;
      margin-bottom: 64px;
    }
    @media (max-width: 576px) {
      max-width: 506px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  ${({ isTall, reference }) =>
    isTall &&
    reference.__typename !== "ContentfulComponentForm" &&
    `
    min-height: 900px;
    .container {
      min-height: 900px;
    }
    @media (max-width: 992px) {
      height: auto;
      min-height: unset;
      .container {
        margin-bottom: 0!important;
        min-height: unset;
      }
    }
    .img-side {
      .gatsby-image-wrapper {
        position: absolute;
        right: 0;
        height: 900px!important;
        max-width: 50%!important;
        width: unset!important;
        transform: translateY(-50%);
        object-fit: cover;
        object-position: left;
      }
      img {
        height: 900px!important;
        overflow: hidden;
        object-position: left;
      }
      @media (max-width: 992px) {
        width: 100vw!important;
        .gatsby-image-wrapper {

          position: relative;
          transform: none;
          max-width: 100%!important;
          height: auto!important;
          img {
            height: auto!important;
          }
        }
      }
    }
  `}
  ${({ reference }) =>
    reference.__typename === "ContentfulComponentForm" &&
    `
    padding: 96px 0;
    @media (max-width: 992px) {
      padding: 0;
    }
    .container {
      align-items: flex-start!important;
    }
  `}
  
  .text-content-side {
    width: 48%;
    text-align: left;
    display: flex;
    height: 100%;
    flex-direction: column;
    ${({ mobileTextAlign }) =>
      mobileTextAlign &&
      mobileTextAlign === "Center" &&
      `
      @media (max-width: 768px) {
        text-align: center;
      }
    `}
  }
  .text-content-wrapper {
    align-items: center;
  }
  .img-side {
    width: 52%;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .btn-wrapper {
    margin-top: 24px;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    button {
      margin-right: 15px;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: stretch;
    .text-content-side {
      width: 100%;
      padding: 0px;
    }
    .img-side {
      width: 100%;
    }
  }
`

export const CategoriesWrapper = styled.div`
  margin-bottom: 24px;
`

export const Category = styled.div`
  display: inline-block;
  margin-right: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #101828;
  padding: 5px 10px;
  border-radius: 35px;
`

export const HeroStandardNoImage = styled.div`
  margin-top: 100px;
  font-family: Roboto, sans-serif;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ background }) => `
    background: ${background};
  `}
  .background-image {
    position: absolute;
    min-width: 100vw;
    height: 100%;
    z-index: 0;
  }
  .container {
    display: flex;
    min-height: 500px;
    margin: 0 auto;
    max-width: 1170px;
    width: 100%;
    z-index: 1;
    padding: 0 15px;
    position: relative;
    align-items: center;
    @media (max-width: 992px) {
      max-width: 770px;
      .breadcrumb-wrap {
        position: absolute;
        top: 24px;
      }
    }
    @media (max-width: 576px) {
      max-width: 506px;
    }
  }
  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    max-width: 770px;
    margin: 0 auto;
    height: 100%;
  }
  .btn-wrapper {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    button {
      margin-right: 15px;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
`

export const BreadcrumbContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1170px;
  width: 100%;
  align-items: center;
  padding: 25px 15px 0 15px;
  @media (max-width: 992px) {
    max-width: 770px;
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`
