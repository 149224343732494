import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import fetchLottie from "../../utils/fetchLottie";

export const LottieWrapper = ({ imageAnimation }) => {
  const [animation, setAnimation] = useState()

  useEffect(()=> {
    fetchLottie(imageAnimation, setAnimation)
  }, [imageAnimation])

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} />
}