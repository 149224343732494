import { theme } from "@theme"

const setTheme = background => {
  let headingColor
  let fontColor
  let kickerColor
  let backgroundColor
  let backgroundPattern

  if (background === "dark") {
    headingColor = theme.textColor.headingLight
    fontColor = theme.textColor.bodyLight
    kickerColor = theme.textColor.electricBlue
    backgroundColor = "#101828"
  } else if (background === "blue") {
    headingColor = theme.textColor.headingLight
    fontColor = theme.textColor.bodyLight
    kickerColor = theme.textColor.electricBlue
  } else if (background === "blue-pattern") {
    headingColor = theme.textColor.headingLight
    fontColor = theme.textColor.bodyLight
    kickerColor = theme.textColor.electricBlue
    backgroundPattern = "blue-pattern"
  } else if (background === "purple-pattern") {
    headingColor = theme.textColor.headingLight
    fontColor = theme.textColor.bodyLight
    kickerColor = theme.textColor.electricBlue
    backgroundPattern = "purple-pattern"
  } else if (background === "dark-pattern") {
    headingColor = theme.textColor.headingLight
    fontColor = theme.textColor.bodyLight
    kickerColor = theme.textColor.electricBlue
    backgroundPattern = "dark-pattern"
  } else if (background === "light-pattern") {
    headingColor = theme.textColor.heading
    fontColor = theme.textColor.grey600
    kickerColor = theme.textColor.grey600
    backgroundPattern = "light-pattern"
  } else {
    headingColor = theme.textColor.heading
    fontColor = theme.textColor.grey600
    kickerColor = theme.textColor.grey600
    if (background === "light") {
      backgroundColor = "#f2f4f7"
    } else {
      backgroundColor = "white"
    }
  }

  return {
    headingColor,
    fontColor,
    kickerColor,
    backgroundPattern,
    backgroundColor,
  }
}

export default setTheme
